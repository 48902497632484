import service from "@/utils/request";

/**
 * 添加 打卡日期
 * @returns {AxiosPromise}
 * @param clock
 */
export function addClock(clock){
    return service(
        {
            url: 'clock/addClock',
            method: 'post',
            header:{
                "Content-Type":"application/json" //如果写成contentType会报错
            },
            data:clock
        })
}

/**
 * 获取当前用户的打卡信息
 * @param userId
 * @returns {AxiosPromise}
 */
export function selectClockById(userId){
    return service(
        {
            url: 'clock/selectClockById',
            method: 'post',
            params:{
                userId
            }
        })
}
