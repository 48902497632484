<template>
  <div class="clock">
    <el-row>
      <el-col :span="20">
        <el-calendar>
          <template
              slot="dateCell"
              slot-scope="{data}">
            <p>{{ isContain(data.day) }}</p>
          </template>
        </el-calendar>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" :disabled="flag" @click="clickClock">{{ content }}</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {addClock, selectClockById} from "@/api/clock";

export default {
  name: "index",
  data() {
    return {
      clock: [],
      flag: false,
      content: "点击签到"
    }
  },
  mounted() {
    let id = this.$store.state.user.id;
    selectClockById(id).then(result => {
      this.clock=result.data.clock;
      // 判断今天是否该打卡
      this.jungle();
    }).catch(error => {
      this.$message(error.message)
    })
  },
  methods: {
    // 判断今天是否该打卡
    jungle(){
      let date = new Date();
      let now = this.formatDate(new Date())
      if (this.clock === undefined || this.clock === "") {
        this.flag = true;
        this.content = "签到没有数据";
        return;
      }
      // 如果今天已经打过卡了，就设置禁用
      if (this.clock.includes(now)) {
        this.flag = true;
        this.content = "已经打卡"
      }
    },
    clickClock(){
      let clock={
        userId:this.$store.state.user.id
      }
      let that=this;
      addClock(clock).then(result=>{
        this.$message({
          message:result.data.clock,
          duration:2*1000,
          type:"success"
        })
        selectClockById(this.$store.state.user.id).then(result=>{
          this.clock=result.data.clock;
          that.jungle();
        }).catch(error=>{
          this.$message({
            message:error.message,
            type:"error",
            duration:2*1000
          })
        })
      }).catch(error=>{
        this.$message({
          message:error.message,
          type:"error",
          duration:2*1000
        })
      })
    },
    formatDate(date) {
      // date = new Date(Date.parse(date.replace(/-/g, "/"))); //转换成Data();
      // console.log(date);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    },
    isContain(a) {
      if (this.clock) {
        let tag = this.clock.includes(a);
        if (tag === true) {
          return "打卡" + "✔️"
        } else {
          return a.split('-').slice(1).join('-') + "未打卡"
        }
      } else {
        return a.split('-').slice(1).join('-')
      }
    }
  }
}
</script>

<style scoped >
.clock {
  width: 90%;
  margin: auto;
}
</style>
